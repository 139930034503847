<template>
  <div class="pay-list">
    <csWidget v-if="showCs" :csId="'1'"></csWidget>
  </div>
</template>

<script>
import csWidget from "@/components/csWidget.vue";
import { courseApi } from "@/api/courseApi.js";
import { isWeiXin, isMobile } from "@/util/browser.js";
export default {
  components: { csWidget },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      wechatImg: require("@/assets/images/wechat-icon.png"),
      customserviceImg: require("@/assets/images/custom-service.png"),
      showCs: false,
      courseId: 0,
    };
  },
  methods: {
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.courseId = this.$route.query.id;
      }
      return this.courseId;
    },
    getCourse(courseId) {
      courseApi.courseInfo(courseId).then((ret) => {
        if (ret && ret.code == 0 && ret.data) {
          const isWeiXinFlag = isWeiXin();
          const isMobileFlag = isMobile();
          console.log("isWeixin:", isWeiXinFlag, "isMobile:", isMobileFlag);
          if (isWeiXinFlag) {
            this.$router.push({
              path: "/pay/wxpay",
              query: { id: this.courseId },
            });
            return;
          }
          if (isMobileFlag) {
            this.$router.push({
              path: "/pay/h5pay",
              query: { id: this.courseId },
            });
            return;
          }
          this.$router.push({ path: "/pay/pcpay", query: { id: this.courseId } });
        } else {
          this.showCs = true;
        }
      });
    },
  },
  mounted() {
    const courseId = this.getId();
    if (!courseId) {
      this.showCs = true;
      return;
    }
    this.getCourse(courseId);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.pay-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
